.spin {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

.reportGeneration{
  overflow: hidden!important;
}