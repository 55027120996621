.MuiInputBase-root.Mui-disabled .ProseMirror p{
  opacity: 1!important;
}

.MuiInputBase-input.Mui-disabled{
  color: rgba(0, 0, 0, 0.5)!important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.5)!important;
}

.currency-custom{
  .MuiInputAdornment-root{
      display: none!important;
    }

  .MuiInputBase-input{
    text-align: left;
  }

  .MuiInputBase-input.Mui-disabled{
    color: rgba(0, 0, 0, 0.6)!important;
  }
}

nav{
  margin-bottom: 12px!important;
  margin-left: 2px!important;
}

a{
  color: #042360
}

.comment-wrap{
  white-space: normal
}

.comment-wrap > p{
  word-break: break-all;
}

.MuiChip-root.Mui-disabled{
  opacity: 0.9!important;
}