.print-ticket-template{
    display: none;
}

@media print {
    @page{size:auto; margin:5mm 15mm}

    header{
        display: none!important;
    }

    nav{
        display: none!important
    }

    .hide{
        display: none!important;
    }

    .print-ticket-template{
        display:block;
    }

    table, th, td {
        border:1px solid black;
        font-size: 12px;
    }

    td{
        padding: 2px;
    }

    tr    { page-break-inside:avoid; page-break-after:auto }

    table {
        width: 100%;
        border-collapse: collapse;
        page-break-inside:auto;
        table-layout: fixed;
        word-wrap: break-word;
    }

    .page-break {page-break-before: always;}
}

