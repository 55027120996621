
.wrapper {
  margin: 0;
  max-width: 100%;
  max-height: none;
  max-height: -webkit-fill-available;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.bam-table {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  font-size: 0.77rem;
  border: 1px solid #e1e1e1;

  table  {
    height:1px;
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
  }

  th, td {
    padding: 8px!important;
    border: 1px solid #ddd;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    font-size: 0.82rem;
    text-align: left;
    border-bottom: 1px solid #d3d3d3;
  }

  thead > tr{
    box-shadow: 1px 2px 5px 0px #dbdbdb;
  }

  th{
    background-color: #0F7AC9;
    color: #ffffff;
    min-height: 45px;
  }

  th.disabled {
    background:#0F7AC9!important;
  }

  .editableCell th{
    background: #999999 !important;
    color: white !important;
    text-align: center;
  }

  .editableCell.edit{
    th:nth-child(2){
      text-align: unset;
    }
  }

  .editableCell{
    td{
      font-weight: bold;
    }
  }

  .editableCell{
    th:nth-child(1){
      text-align: unset;
    }

    .ProseMirror {
      p:first-child {
        margin: 0;
      }
    }
  }

  .ProseMirror {
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .textEdit{
    font-size: 0.75rem;
    height:100%;
    width:100%;
    color: black;
    font-family: "Roboto", sans-serif;
    line-height: normal;
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    display: table-cell;
    vertical-align: middle;
  }

  .add-cell{
    background: #6ee1a8;
  }
  .delete-cell{
    background: #ff7171;
  }

  .modified-cell{
    background-color: #ffff70;
  }

  .disabled-cell{
    opacity: 0.6;
    user-select: none;
  }

  .fixed-cell{
    background: #efefef;
  }
}

.bam-table .wrap-text{
  word-wrap: break-word;
  width: 100%;
  padding: 6px 0px 7px!important;
  p{
    margin:unset;
  }
}

.bam-table tr {
  cursor: cell;
  position: relative;
}

.no-border{
  border: unset!important;
}

.cell-focused {
  border: 3px solid #525252!important;
}

.obm-table{
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: block;
  font-size: 0.75rem;
  border: 1px solid #e1e1e1;

  table {
    height: 1px;
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
  }

  th, td {
    border: unset;
  }

  td{
    border-bottom: 1px solid #e5e5e5!important;
  }

  th {
    background-color: #fff;
    color: #5d5d5d;
    border: unset;
    border-bottom: 1px solid #d3d3d3;
  }

  th.disabled {
    background: #0F7AC9 !important;
  }

  .no-hover:hover{
    background: unset;
  }

  table th,
  table td {
    border: 1px solid #dfdfdf;
    white-space: nowrap;
    padding: 15px 23px;
    background: white
  }

  tr:hover td {
    background-color: #f8f8f8;
  }

  table tr th:first-child,
  table td:first-child {
    position: sticky;
    max-width: 100px;
    width: 100px;
    min-width: 100px;
    word-wrap: break-word;
    white-space: break-spaces;
    left: 0;
    z-index: 10;
  }

  table td:nth-child(2) {
    position: sticky;
    max-width: 250px;
    width: 250px;
    min-width: 250px;
    word-wrap: break-word;
    white-space: break-spaces;
    left: 100px;
    z-index: 10;
  }

  table td:nth-child(3) {
    position: sticky;
    max-width: 150px;
    width: 150px;
    min-width: 150px;
    word-wrap: break-word;
    white-space: break-spaces;
    left: 350px;
    z-index: 10;
  }

  table td:nth-child(4) {
    position: sticky;
    max-width: 100px;
    min-width: 100px;
    left: 500px;
    word-wrap: break-word;
    white-space: break-spaces;
    z-index: 10;
    border-right:2px solid #ababab;
  }

  table tr th {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
  }

  table tr:nth-child(2) th{
    position: sticky;
    top: 26px;
    z-index: 9;
    background: #0F7AC9 !important;
    color: white !important;
  }

  table tr:nth-child(2) th:first-child{
    min-width: 250px;
    left:0;
    z-index: 11;
  }

  table tr:nth-child(2) th:nth-child(2){
    min-width: 250px;
    left: 100px;
    z-index: 11;
  }

  table tr:nth-child(2) th:nth-child(3){
    min-width: 150px;
   left: 350px;
    z-index: 11;
  }

  table tr:nth-child(2) th:nth-child(4){
    min-width: 100px;
    left: 500px;
    z-index: 11;
    border-right: 2px solid #ababab;
  }

  table tr th:first-child {
    z-index: 11;
  }

  tr:hover{
    background: #f8f8f8!important;
  }
}

.inline-table{
  line-height: 25px!important;

  th{
    background: #0F7AC9;
    color: white;
    padding: 0 23px !important;
    margin: 20px!important;
  }

  th:hover{
    background: #0F7AC9!important;
    color: white;
  }

  tr:hover{
    background: unset!important;
  }
}

