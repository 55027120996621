.import-excel {
  display: block;
  overflow: auto;
  font-size: 0.75rem!important;
  th{
    font-size: 0.75rem!important;
  }
  td{
    font-size: 0.75rem!important;
  }
  .table {
    .tbody {
      height:calc(100vh - 535px);
    }

    .tr {
      align-items: center;
      height: 40px;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid #cfcfcf;
      border-bottom: 1px solid #cfcfcf;
      position: relative;
      background:white;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        background:#0F7AC9 ;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        touch-action: none;

        &.isResizing {
          background: #004F98;
        }
      }
    }
  }
}

#gradient {
  animation: blinking 2s infinite;
}

@keyframes blinking {
  50% {opacity: 0.4;}
}

.dot-spin {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 3px; 
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #bababa, 12.727926px -12.727926px 0 0 #bababa, 18px 0 0 0 #bababa, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {
  0%, 100% {
    box-shadow: 0 -18px 0 0 #bababa, 12.727926px -12.727926px 0 0 #bababa, 18px 0 0 0 #bababa, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #bababa, 18px 0 0 0 #bababa, 12.727926px 12.727926px 0 0 #bababa, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #bababa, 12.727926px 12.727926px 0 0 #bababa, 0 18px 0 0 #bababa, -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #bababa, 0 18px 0 0 #bababa, -12.727926px 12.727926px 0 0 #bababa, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #bababa, -12.727926px 12.727926px 0 0 #bababa, -18px 0 0 0 #bababa, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #bababa, -18px 0 0 0 #bababa, -12.727926px -12.727926px 0 0 #bababa;
  }
  75% {
    box-shadow: 0 -18px 0 0 #bababa, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #bababa, -12.727926px -12.727926px 0 0 #bababa;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #bababa, 12.727926px -12.727926px 0 0 #bababa, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #bababa;
  }
}